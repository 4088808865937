:root {
    --pending--color: #ffc107;
    --approved--color: #28a745;
    --declined--color: #dc3545;
    --busy--color: #6c757d;
    --done--color: #17a2b8;
    --client_cancel--color: #343a40;
    --primary--color: #007bff;
    --disabled--color: #dee2e6;
    --download--icon--color: #6c757d
}