@import './common_css/loadingOverlay.css';
@import './dashboardLocalization.css';

.plugin_container {
    padding-left: 15px;
    padding-right: 15px;
    height: 95vh;
}

.margin_top_row {
    margin-top: 1rem;
}

.tabs_style {
    padding-top: 1rem;
}

.noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 40%;
    z-index: 1;
    padding: 20px;
    background-color: white;
    border: 1px solid #dee2e6;
}